.editable {
    input,textarea  {
        padding: 5px;
        margin: 0 -5px;
        border-radius: 4px;
        border: rgba(0,0,0,0) 1px solid;
    }
    .MuiInput-underline:after,
    .MuiInput-underline:before {
        border: none !important;
    }
    .MuiInput-base-input.Mui-disabled {
        opacity: 0.4;
    }
    label {
        z-index: 100;
        &.Mui-disabled {
            opacity: 0.4;
        }
    }
    &.hover {
        label:not(.Mui-disabled) {
            color: #000;
        }
    }
    &.multiline {
        label {
            padding-top: 6px;
        }
    }
    &.editable-field {
        input, textarea {
            border: #eee 1px solid;
        }
    }
}
.editable-image {
    .wrapper {
        max-width: 200px;
        position: relative;
        .img {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            .bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background: transparent 50% 50% no-repeat;
                background-size: cover;
            }
        }
    }
}
.ris-back {
    text-decoration: none;
    .back-text {
        display: 'inline-block';
        position: 'relative';
        top: -7px;
        margin-left: 5px;
    }
}
.ris-link {
    text-decoration: inherit;
    color: #555;
    cursor: pointer;
    &:hover {
        color: #000;
    }
    &.disabled {
        pointer-events: none;
    }
}
.page-title {
    margin: 5px 16px 5px;
    padding-bottom: 5px;
    font-size: 20px;
}
.loading-screen {
    opacity: 0.7;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    align-items: center;
    justify-content: center;
    display: none;
    left: -10px;
    top: -10px;
    background: #fff;
    z-index: 1000;
    &.active {
        display: flex;
    }
}
.complete-state-badge {
    display: block;
    margin: 0 0 10px 0;
    color: #0C0;
    &.incomplete {
        color: #C00;
    }
    &.moderation {
        color: #F80;
    }
}
.dashboard-search-content {
    position: relative;
    .load-more {
        margin: 10px auto;
        display: block;
        padding: 6px 15px;
    }
    .dashboard-search-result {
        padding: 10px 0;
        display: none;
        &.current {
            display: block;
        }
    }
}
.ris-breadcrumbs {
    margin: 0 0 15px 0;
    .item {
        text-decoration: none;
        span {
            display: inline-block;
            position: relative;
            top: -7px;
            margin-left: 5px;
        }
    }
}
.under-construction {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 70px);
    flex-wrap: wrap;
    h1 {
        font-size: 48px;
        text-align: center;
        width: 100%;
    }
    p {
        font-size: 16px;
        text-align: center;
        width: 100%;
        padding: 30px 0 0 0;
    }
}
.common-paper {
    height: 100%;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
}
.fake-multiple {
    .option {
        display: block;
    }
}
.doctor-card {
    margin-left: -20px;
    margin-bottom: 16px;
    padding: 16px 16px 16px 20px;
    background: #f5f5f5;
    border: #eee 1px solid;
    box-shadow: 2px 1px 4px rgba(0,0,0,0.15);
    .surname {
        margin-top: 0;
        margin-bottom: 0;
        input {
            font-size: 24px;
        }
    }
    .io {
        margin-top: 0;
    }
    .old {
        color: #666;
        display: block;
        margin-top: 8px;
    }
    .editable-image {
        .bg {
            border-radius: 50%;
        }
    }
    .birthday {
        .MuiInput-underline:after,
        .MuiInput-underline:before {
            display: none !important;
        }
    }
}
.roles-list {
    .item {
        .ris-link {
            padding: 10px 15px;
            display: block;
            border: transparent 2px solid;
            margin-right: 15px;
            &:hover {
                background: #edf7ed;
                color: #000;
                border-color: #eee;
            }
        }
    }
}
.service-editor {
    position: relative;
    .price {
        padding: 3px 10px;
        min-height: 53px;
        margin-top: 10px;
        input {
            font-size: 32px;
        }
    }
    .service-title-wrapper {
        margin-left: -20px;
        padding: 3px 10px 3px 20px;
        background: #444;
        min-height: 53px;
        .fake {
            height: 1px;
            margin-bottom: -1px;
            visibility: hidden;
            overflow: hidden;
        }
        .service-title {
            margin-top: 0;
            margin-bottom: 0;
            textarea {
                color: #fff;
                font-size: 16px;
            }
            &.short textarea {
                font-size: 24px;
            }
        }
    }
    .MuiAlert-message {
        width: 100%;
    }
}
.assignments-panel {
    ul {
        display: block;
        margin: 0 0 7px 0;
        padding: 0;
        li {
            display: block;
            padding: 0;
            margin: 8px 0 8px 0;
            .doctor, .department, .assignment {
                border: #ddd 3px solid;
                padding: 8px;
                background: #eee;
                opacity: 0.4;
                &.allowed {
                    background: #fff;
                    opacity: 1;
                    cursor: pointer;
                    &:hover {
                        background: #edf7ed;
                        color: #000;
                    }
                    &.active {
                        background: #83c985;
                        color: #000;
                    }
                    &.doc-inactive {
                        border-color: #C66;
                        background: #eee;
                        color: #666;
                        .inactive {
                            font-style: italic;
                        }
                    }
                    &.doc-invisible-in-ris {
                        border-color: #C66;
                        background: #eee;
                        color: #666;
                        .inactive {
                            font-style: italic;
                        }
                    }
                }
                .doctor-link {
                    display: block;
                    float: right;
                    font-size: 30px;
                    margin-top: 5px;
                    margin-right: 10px;
                }
                .root {
                    display: block;
                    margin-bottom: 3px;
                    font-size: 80%;
                    &:after {
                        content: " >";
                    }
                }
            }
        }
    }
}
.users-list {
    position: relative;
}

.modern-user-list {

    .user-image {
        border-radius: 4px;
        width: 100%;
        object-fit: cover;
        width: 130px;
        height: 130px;
      }
      .chip-label {
        background-color: #eee;
        padding: 4px 12px;
        margin-top: 8px;
        margin-right: 4px;
        margin-left: 4px;
        border: #9e9e9e 1px solid;
        border-radius: 2px;
        margin-right: 8px;
        margin-left: 8px;
      }
      .hire-button {
        margin-right: 20px;
        margin-top: 8px;
        padding: 9px 12px;
        line-height: 1;
        width: 96px;
        font-weight: normal;
      }
      .remove-button {
        background-color: #fff;
        color: #9e9e9e;
        margin-top: 8px;
        border: #9e9e9e 1px solid;
        width: 96px;
        font-weight: normal;
        padding: 9px 12px;
        line-height: 1;
      }
      .pointer {
        cursor: pointer;
      }
}

.link-container {
    display: flex; /* Делаем контейнер flex-контейнером */
    justify-content: space-between; /* Равномерно распределяем элементы внутри контейнера */
    align-items: center; /* Выравниваем элементы по вертикали */
}

.image-container {
    margin-right: 10px; /* Расстояние справа от изображения (можете настроить по вашему усмотрению) */
}

.assigned-services, .section-doctor {
    display: block;
    position: relative;
    .assigned-text-filter {
        margin-top: 0;
        label {
            color: #aaa;
        }
        input {
            background: #fff;
            border: #ccc 1px solid;
        }
    }
    ul {
        display: block;
        margin: 0 0 16px 0;
        padding: 0;
        list-style: none;
        li {
            display: block;
            margin: 0 0 8px 0;
            padding: 0;
            list-style: none;
            .service, .doctor {
                border: #ddd 2px solid;
                background: #f5f5f5;
                padding: 8px;
                &.inactive {
                    background: #ccc;
                    opacity: 0.65;
                    .inactive-marker {
                        color: #a00;
                        margin-bottom: 10px;
                    }
                }
                .heading {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -12px;
                    padding: 8px 12px;
                    background: #666;
                    color: #ddd;
                    .title, .code, .state {
                        display: block;
                        color: #ddd;
                    }
                    .code, .state {
                        width: 50%;
                    }
                    .state {
                        text-align: right;
                        .complete-state-badge {
                            display: inline-block;
                            background: #eee;
                            padding: 0px 8px;
                        }
                    }
                    &:hover {
                        .title {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.doctors-detail {
    .assigned-services {
        .service {
            .doctor {
                display: none;
            }
        }
        .MuiAutocomplete-inputRoot {
            background: #fff;
        }
    }
}
.floating-bottom-wrapper {
    height:100px;
    .floating-bottom {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 10000;
        .inner {
            background: #fff;
            padding: 10px 15px;
            box-shadow: 0 0 5px 5px rgba(0,0,0,0.15);
            @media screen and (min-width: 1280px) {
                margin-left: 19rem;
            }
        }
    }
}

.log-list {
    height: auto;
    .list {
        .log-item {
            border-top: 1px solid #eee;
            margin: 0px 0 4px 0;
            padding: 8px 16px 4px 16px;
            .action-open {
                display: inline-block;
            }
            .timestamp {
                a {
                    color: #aaa;
                    font-size: 12px;
                    text-decoration: none;
                }
            }
            .author {
                text-align: right;
                color: #aaa;
                font-size: 12px;
                a {
                    color: #888;
                    text-decoration: none;
                    &:hover {
                        color: #000;
                    }
                }
            }
            .title {
                margin-top: 4px;
                margin-bottom: -4px;
                a {
                    color: #0A8FDC;
                    font-weight: bold;
                    text-decoration: none;
                }
                &.delete a {
                    color: #A00;
                }
                &.patients_access a {
                    color: #F1009E;
                }
                &.create a {
                    color: #0A0;
                }
            }
            .description, .action-close {
                display: none;
            }
            &:first-child {
                margin-top: 0;
                border-top: none;
            }
            &.open {
                .action-open {
                    display: none;
                }
                .action-close {
                    display: inline-block;
                }
                .description {
                    display: block;
                }
            }
        }
    }
}

.profosmotr {
    .service-item {
        border: #eee 1px solid;
        margin-bottom: 10px;
        padding: 10px;
        .desc {
            margin-top: 10px;
            color: #3a3;
            font-size: 12px;
        }
    }
    .empty-services {
        margin: 0 0 15px 0;
        text-align: center;
        color: #a00;
    }
    .factors {
        margin-bottom: 25px;
        ul {
            padding-left: 15px;
            width: 100%;
            display: none;
            list-style: none;
            &.level-1 {
                display: block;
            }
            li {
                margin-bottom: 4px;
                &.open > ul {
                    display: block;
                }
                a {
                    text-decoration: none;
                }
                .switch {
                    display: inline-block;
                    &:before {
                        content: "▶";
                        padding-right: 5px;
                    }
                    &.active:before {
                        content: "▼";
                    }
                }
                b {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top;
                }
            }
        }
    }
}

.doctors-list {
    .pointer {
        cursor: pointer;
    }
    .item {
        .photo {
            border-radius: 4px;
            width: 100%;
            height: 180px;
            object-fit: cover;
            object-position: 50% 50%;
            @media (min-width: 768px) {
                max-width: 256px;
                max-height: 256px;
            }
        }
        .chip-label {
            background-color: #eeeeee;
            border: #9e9e9e 1px solid;
            padding: 4px 12px;
            margin: 8px 4px 0 4px;
            border-radius: 2px;
            @media (min-width: 1024px) {
                margin-right: 8px;
                margin-left: 8px;
            }
        }
    }
}

.crema-menu-item {
    height: 40px;
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    text-decoration: none !important;
    width: calc(100% - 16px);
    border-radius:  030px 30px 0px;
    padding-left: 24px !important;
    padding-right: 24px !important;
    &.nav-item-header {
        text-transform: uppercase;
    }
    &.active {
        background-color: #0A8FDC;
        pointer-events: none;
        transition: border-radius .15s cubic-bezier(0.4,0.0,0.2,1);
        .nav-item-text {
            color: #fff !important;
            font-weight: 400;
        }
        .nav-item-icon {
            color: #fff !important;
        }
    }

    &:hover, &:focus {
        .nav-item-text {
            color: #fff;
        }
        .nav-item-icon {
            color: #fff;
        }
        .nav-item-icon-arrow {
            color: #fff;
        }
    }
    .nav-item-icon {
      color: #808183;
    }
    .nav-item-text {
      color: #808183;
      font-weight: 400;
    }
    .list-icon {
      font-size: 18px;
    }
    .list-item-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
    }
}
.crema-menu-group {
    height: 40px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 24px !important;
    padding-right: 24px !important;
    color: #808183;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none !important;
    @media (min-width: 1024px) {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    &.nav-item-header {
        text-transform: uppercase;
    }
}
.app-sidebar {
    .MuiCollapse-container {
        &.collapse-children {
            padding-left: 12px;
            @media (min-width: 1024px) {
                padding-left: 24px;
            }
        }
    }
}

.info-field {
    margin-bottom: 15px;
    width: 100%;
    > .ck {
        color: #333;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #ccc 1px solid !important;
        &.ck-focused {
            border: #000 1px solid !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 4px !important;
        }
    }
    [data-placeholder]:before {
        display: block !important;
        content: attr(data-placeholder);
        // position: absolute;
        margin-top: -23px !important;
        font-size: 12px !important;
        background: #fff;
        padding: 0 5px;
        right: auto !important;
        color: #333;
    }
    .ck-focused {
        [data-placeholder]:before {
            color: #0A8FDC;
        }
    }
    .ck-read-only {
        [data-placeholder]:before {
            color: #333 !important;
            opacity: .4 !important;
        }
    }
    &.has-data {
        [data-placeholder]:before {
            margin-top: -23px !important;
            font-size: 12px !important;
        }
    }
    .attachments-list {
        margin-top: 10px;
        .attachments-panel {
            text-align: right;
            .label {
                display: none;
            }
            button {
                font-size: 12px !important;
            }
        }
        ul {
            display: block;
            margin: 0 0 10px 0;
            .attachments-item {
                display: flex;
                border-bottom: #eee 1px solid;
                margin-bottom: 2px;
                padding-bottom: 4px;
                .file {
                    width: 100%;
                    a {
                        display: block;
                        color: #0A8FDC;
                        text-decoration: none;
                        &:hover {
                            color: #0A8FDC;
                            text-decoration: underline;
                        }
                    }
                }
                .move {
                    margin-right: 10px;
                }
                .move, .buttons {
                    display: flex;
                    justify-content: space-between;
                    a {
                        font-size: 12px;
                        text-align: center;
                        font-weight: bold;
                        display: block;
                        width: 22px;
                        height: 22px;
                        line-height: 14px;
                        text-decoration: none;
                        padding: 2px;
                        background: #ddd;
                        color: #444;
                        margin-right: 3px;
                        cursor: pointer;
                        border-radius: 50%;
                        border: #ccc 1px solid;
                        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
                        &.delete {
                            color: #c00;
                        }
                        &:hover {
                            background: #ccc;
                        }
                        &.copy {
                            line-height: 20px;
                        }
                    }
                }
                &:first-child .up,
                &:last-child .dn {
                    opacity: 0.3 !important;
                    pointer-events: none;
                }
            }
        }
    }
}
.ck.ck-reset_all {
    overflow: hidden;
}
.ck.ck-balloon-panel {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    > .ck.ck-toolbar {
        width: 100000px;
        background: transparent;
        > .ck.ck-toolbar__items {
            justify-content: end;
            >:not(.ck-toolbar__line-break) {
                background: #eee;
                &:hover {
                    background: #ccc;
                }
            }
        }
    }
}
.ck-content {
    overflow: visible !important;
}
.ck-content, .wiki-viewer .content {
    ol, ul {
        margin-left: 26px;
    }
    ol, ul, p, table, .yt-container,
    h1, h2, h3, h4, h5 {
        margin-bottom: 12px;
    }
    .table {
        table {
            border-spacing: 0;
            border-collapse: collapse;
        }
        td {
            border: #999 1px solid;
            padding: 5px;
        }
    }
    figure.image {
        text-align: center;
    }
    .ck-media__wrapper[data-oembed-url*=youtu] {
        max-width: 600px;
    }
    .yt-container {
        display: block;
        margin: 0 auto 12px auto;
        max-width: 600px;
        .yt-wrapper {
            position: relative;
            height: 0;
            padding-bottom: 56.2493%;
            .yt-frame {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}
.wiki-editor {
    .wiki-sections {
        padding: 10px;
        border: #ccc 1px solid;
        border-radius: 4px;
        margin-bottom: 15px;
        ul {
            margin: 0;
            list-style: none;
            padding: 0 0 0 15px;
            li {
                margin: 0;
                padding: 0;
                list-style: none;
                display: block;
                .MuiCheckbox-root {
                    padding: 4px !important;
                }
                .MuiFormControlLabel-root {
                    &:hover {
                        background: #eee;
                    }
                }
            }
        }
        > ul {
            max-height: 400px;
            overflow: auto;
            margin: 15px 0;
        }
    }
}
.ck.ck-link-form,
.ck.ck-link-actions {
    background: #eee !important;
}
.MuiAlert-standardWarning {
    background: #fff !important;
}

.left-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 58px;
    width: 304px;
    padding: 10px 0;
    background: #313541;
    @media (min-width: 1920px) {
        height: 65px;
    }
    .calc {
        color: #808183;
        text-decoration: none !important;
        padding: 8px 24px;
        display: flex;
        &:hover {
            color: #fff;
            .MuiSvgIcon-root {
                color: #fff;
            }
        }
        span {
            display: block;
            padding-top: 2px;
        }
        .MuiSvgIcon-root {
            color: #808183;
            margin-right: 24px;
            display: block;
        }
    }
}
.left-footer-top {
    position: fixed;
    bottom: 58px;
}
.left-footer-top-top {
    position: fixed;
    bottom: 115px;
}
.registry-buttons {
    position: fixed;
    bottom: 150px;
    padding: 20px;
}
.calls-notifications {
    position: fixed;
    top: 75px;
    right: 30px;
    z-index: 100;
    .patient-item {
        border: 1px solid gray;
        margin-top: 0.5rem;
        background-color: #a0e1a0;
        border-radius: 0.5rem;
        min-width: 35rem;
        .item {
            margin: 0.5rem;
        }
        .close {
            margin: 0.5rem;
            cursor: pointer;
        }
    }
}

.MuiButton-contained {
    &.button-red {
        background: #d00;
        color: #fff;
        &:hover {
            background: #a00;
            color: #fff;
        }
    }
}
.moderation-list {

    .filter {
        padding-bottom: 20px;
        button {
            margin-right: 15px;
            margin-top: 15px;
        }
        > span {
            display: inline-block;
            white-space: nowrap;
        }
    }
    .moderation-item {
        .ris-link {
            color: #0A8FDC;
            &:hover {
                color: #19a2f1;
            }
        }
        .entity-info {
            padding: 0 0 10px 15px;
            border-left: #aaa 4px solid;
            ul {
                display: block;
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    display: block;
                    padding: 0;
                    margin: 0 0 4px 0;
                    .title {
                        font-weight: bold;
                    }
                    .values {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}
.wiki-viewer {
    padding: 20px 20px 50px 20px;
    h1.title {
        text-align: center;
        border-bottom: #eee 1px solid;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }
    div.content {
        margin-bottom: 30px;
    }
    .block-attached {
        border-top: #eee 1px solid;
        margin-bottom: 15px;
        padding-top: 15px;
        .heading {
            font-weight: bold;
            color: #555;
        }
        .list {
            .ris-link {
                color: #0A8FDC;
            }
        }
    }
    .block-files {
        border-top: #eee 1px solid;
        margin-bottom: 15px;
        padding-top: 15px;
        .heading {
            font-weight: bold;
            color: #555;
        }
        .list {
            .ris-link {
                color: #0A8FDC;
            }
        }
    }
}
.add-wiki {
    margin: 8px 15px 10px 0 !important;
}

.wiki-sections-editor {
    h2 {
        border-bottom: #eee 1px solid;
        margin-bottom: 10px;
        font-size: 18px;
        padding-bottom: 5px;
    }
    .edit-section {
        position: relative;
        margin-bottom: 10px;
        padding-bottom: 10px;
        background: #fff;
        padding: 15px;
        border: #eee 1px solid;
        .close {
            display: block;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                background: #eee;
            }
            &:before {
                content: "✖";
            }
        }
        .MuiInput-root {
            width: 100%;
        }
        .editable-field {
            input {
                border: #aaa 1px solid;
            }
        }
        button {
            margin-top: 12px;
            &.cancel {
                background-color: #444;
                margin-left: 15px;
                float: right;
            }
        }
    }
    .sections-list-wrapper {
        padding-bottom: 0;
        > button {
            margin: 8px 15px 10px 0;
        }
        .wiki-sections-open {
            display: inline-block;
            @media screen and (min-width: 960px) {
                display: none;
            }
        }
        .list {
            display: none;
            &.active {
                display: block;
            }
            @media screen and (min-width: 960px) {
                display: block;
            }
        }
        .sections-list {
            display: block;
            padding-left: 0;
            list-style: none;
            margin: 0px 0 0 10px;
            border-bottom: 1px solid #eee;
            border-left: 1px solid #eee;
            background: #fff;
            &.level-0 {
                padding-left: 0;
                margin-left: 0;
                padding-top: 0;
                border: none;
                background: transparent;
                > li {
                    margin: 0 0 8px 0;
                    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.10);
                    background: #fff;
                    > .section-item {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
            li {
                display: block;
                padding: 4px 0;
                margin: 0 0 0 4px;
                .section-item {
                    display: flex;
                    justify-content: left;
                    margin: 4px;
                    position: relative;
                    .title {
                        width: 100%;
                        a {
                            display: block;
                            width: 100%;
                            color: #000;
                            text-decoration: none;
                            font-size: 16px;
                            opacity: 0.7;
                            padding: 0 4px;
                            &:hover {
                                opacity: 1;
                                cursor: pointer;
                            }
                        }
                        &.selected {
                            a {
                                font-weight: bold;
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        opacity: 0;
                        pointer-events: none;
                        padding-right: 4px;
                        a {
                            display: block;
                            margin-left: 6px;
                            color: #0A8FDC;
                            width: 20px;
                            height: 20px;
                            line-height: 15px;
                            font-size: 12px;
                            text-align: center;
                            opacity: 0.85;
                            &.delete {
                                color: #c00;
                            }
                            &:hover {
                                cursor: pointer;
                                opacity: 1;
                            }
                        }
                    }
                    &:hover {
                        .buttons {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                    &:before {
                        content: "";
                        width: 8px;
                        left: -8px;
                        border-top: #ccc 1px solid;
                        position: absolute;
                        top: 50%;
                    }
                }
            }
        }
    }
}

.order-header-buttons div{
    background: white;
    padding: 1rem;
    cursor: pointer;
    border: 1px dotted gray;
    &.active {
        background: #b6d7a8;
    }
}

.react-dropdown-tree-select .dropdown .dropdown-content{
    z-index: 2!important;
}